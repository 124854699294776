.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.city-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.classification-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

label {
  font-weight: 500;
}

@keyframes ui-progress-spinner-color {
  100%,
  0% {
      stroke: #d62d20;
  }
  40% {
      stroke: #0057e7;
  }
  66% {
      stroke: #008744;
  }
  80%,
  90% {
      stroke: #ffa700;
  }
}