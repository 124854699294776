html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: 'Europa', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root, #root>div {
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Europa';
  src: local('Europa'), url(./fonts/EuropaRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'EuropaBold';
  src: local('EuropaBold'), url(./fonts/EuropaBold.ttf) format('truetype');
}

.card {
  background: var(--surface-e);
  padding: 2rem;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}